import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Do you want to understand the language that your users are sending you? Dashbot Phrase Clustering groups semantically similar phrases together to help you better understand what your users are saying, in their own words. With this data, improve your Natural Language Understanding (NLU) and vastly improve your conversational UI.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/features/phrase-clustering/"
      }}>{`Learn More about Phrase Clusters`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      